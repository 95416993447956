<template>
    <div class="division">
        <div class="line right"></div>
        <div class="divisImg">
            <img class="img" :src="`${this.$imgDomain}/www/HealthingCore/division.png`" alt="">
        </div>
        <div class="line left"></div>
    </div>
</template>

<script>

export default {
    components: {
        
    },
    data() {
        return {
            
        };
    },
    props:{
        
    },
    created() {
        
    },
    mounted() {
        
    },
    methods: {
        
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
.division{
    display: flex;
    align-items: center;
    padding: 0 20px;
    .line {
        flex: 1;
        height: 1px;
        background: #858585;
    }
    .left {
        margin-left: 10px;
    }
    .right {
        margin-right: 10px;
    }
    .divisImg {
        width: 70px;
        height: 70px;
    }
}
</style>