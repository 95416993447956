<template>
    <div class="funMedicine">
        <div class="repHead">
            <division></division>
            <div class="hedTitle">
                <div class="title">{{analysisData.title}}</div>
                <div class="subtitle">{{analysisData.translate}}</div>
            </div>
        </div>
        <div class="content">
            <div class="up">
                <div class="left" :style="{'background': `url(${analysisData.leftBag})`}">
                    <div :class="'imgSize' in analysisData ? 'leftImg2' : 'leftImg'">
                        <img class="img" :src="analysisData.img" alt="">
                    </div>
                </div>
                <div class="right">{{analysisData.text}}</div>
            </div>
            <div class="down" :style="{'background': `url(${analysisData.downBag})`}"></div>
        </div>

    </div>
</template>

<script>
import division from './division'

export default {
    components: {
        division
    },
    data() {
        return {
            
        };
    },
    props: ['analysisData'],
    created() {
        
    },
    mounted() {
        
    },
    methods: {
        
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
.funMedicine {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F5F5F5;
    padding: 50px 0 40px 0;
    margin-top: 40px;
    border-radius: 20px;
    .repHead {
        width: 100%;
        .hedTitle {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 40px 0;
            .title {
                font-size: 20px;
                font-weight: 700;
            }
            .size {
                font-size: 20px;
            }
            .subtitle {
                font-size: 14px;
                margin-top: 5px;
            }
            .subtitleSty {
                margin-top: 10px;
                width: 80%;
                display: flex;
                justify-content: space-around;
            }
        }
    }
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        .text {
            width: 70%;
            line-height: 20px;
        }
        .up {
            display: flex;
            border-top: 1px solid #000;
            .left {
                width: 40%;
                display: flex;
                align-items: center;
                justify-content: center;
                .leftImg {
                    width: 60%;
                    height: 70%;
                }
                .leftImg2 {
                    width: 90%;
                }
            }
            .right {
                width: 60%;
                background: #E3F3F6;
                padding: 30px 10px;
            }
        }
        .left,
        .down {
            background-repeat: no-repeat;
            background-position: center;
            background-size:100% 100%;
        }
        .down {
            width: 100%;
            height: 30px;
        }
    }
    
}
</style>