<template>
    <div class='analystab'>
        <div class="anaheader">
            <analysisHeader></analysisHeader>
            <div class="mian">
                <div class="theme">{{analysisTableData.title}}</div>
                <div class="detail">
                    <div class="text">
                        <div :class=" item.type == 'strong' ? 'textInfo strong' : 'textInfo'" v-for="(item, index) in analysisTableData.introduce" :key="index">{{item.text}}</div>
                    </div>
                    <div>
                        <img class="img" :src="analysisTableData.viewImg" alt="">
                    </div>
                </div>
            </div>
        </div>
        
        <div class="anaheader">
            <analysisHeader></analysisHeader>
            <div class="mian">
                <div class="theme">{{analysisTableData.title}}</div>
                <div class="detail">
                    <div class="teble">
                        <div class="th">
                            <div class="tr tr1">不平衡指标</div>
                            <div class="tr tr2">不平衡程度</div>
                        </div>
                        <div class="td" v-for="(item, index) in analysisTableData.tebledata" :key="index">
                            <div class="tr tr1" v-if="index < 2 && item.Ex2 != ''">
                                <div>{{item.Ex3}}</div>
                                <div v-if="item.translate">{{item.translate}}</div>
                            </div>
                            <div class="tr tr2 padd" v-if="index < 2 && item.Ex2 != ''">
                                <div class="degree" :style="{'width': item.Ex2,'background': `url(${item.bagurl})`}"></div>
                            </div>
                        </div>
                    </div>
                    <div class="teble">
                        <div class="th">
                            <div class="tr tr1">不平衡指标</div>
                            <div class="tr tr2">指标解析</div>
                        </div>
                        <div class="td" v-for="(item, index) in analysisTableData.tebledata" :key="index">
                            <div class="tr tr1" v-if="index < 2 && item.Ex2 != ''">
                                <div>{{item.Ex3}}</div>
                                <div v-if="item.translate">{{item.translate}}</div>
                            </div>
                            <div class="tr tr2" v-if="index < 2 && item.Ex2 != ''">
                                <div>{{item.CoreContent}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="anaheader">
            <analysisHeader></analysisHeader>
            <div class="mian">
                <div class="theme">{{analysisTableData.title}}</div>
                <div class="detail analy">
                    <div class="teble">
                        <div class="th">
                            <div class="tr tr1">不平衡指标</div>
                            <div class="tr tr2">指标解析</div>
                        </div>
                        <div class="td" v-for="(item, index) in analysisTableData.tebledata" :key="index">
                            <div class="tr tr1" v-if="item.num > 35 && item.Ex2 != ''">
                                <div>{{item.Ex3}}</div>
                                <div v-if="item.translate">{{item.translate}}</div>
                            </div>
                            <div class="tr tr2" v-if="item.num > 35 && item.Ex2 != ''">
                                <div>{{item.CoreContent}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- <div class="anaheader">
            <analysisHeader></analysisHeader>
            <div class="mian">
                <div class="theme">{{analysisTableData.title}}</div>
                <div class="detail">
                    <div class="teble">
                        <div class="th">
                            <div class="tr tr1">不平衡指标</div>
                            <div class="tr tr2">指标解析</div>
                        </div>
                        <div class="td" v-for="(item, index) in analysisTableData.tebledata" :key="index">
                            <div class="tr tr1">
                                <div>{{item.Ex3}}</div>
                                <div v-if="item.translate">{{item.translate}}</div>
                            </div>
                            <div class="tr tr2">
                                <div>{{item.CoreContent}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import analysisHeader from './analysisHeader.vue'

export default {
    components: {
        analysisHeader
    },
    data() {
        return {
            
        };
    },
    props:['analysisTableData'],
    created() {
        
    },
    mounted() {
        
    },
    methods: {
        
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
.analystab {
    .anaheader {
        margin-top: 40px;
    }
    .mian {
        background: #F5F5F5;
        margin-top: 5px;
        width: 100%;
        border-radius: 0 0 20px 20px;
        padding: 20px 10px;
        .theme {
            height: 60px;
            background: #C6E1EA;
            background-repeat: no-repeat;
            background-position: center;
            background-size:100% 100%;
            font-size: 18px;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .detail {
            border: 1px dashed #ccc;
            border-top: none;
            .strong {
                font-weight: 700;
            }
            .text {
                padding: 20px 5px;
                line-height: 20px;
                .textInfo {
                    text-indent:2em;
                }
            }
            .teble {
                .th {
                    .tr {
                        font-weight: 700;
                    }
                }
                .th,
                .td {
                    display: flex;
                    .tr {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                        padding: 10px 0;
                        border-top: 1px dashed #ccc;
                        border-bottom: 1px dashed #ccc;
                    }
                    .tr1 {
                        width: 20%;
                        div {
                            width: 100%;
                            text-align: start;
                            padding: 0 5px;
                            word-wrap:break-word;
                        }
                    }
                    .tr2 {
                        width: 80%;
                        border-left: 1px dashed #ccc;
                    }
                }
                .td {
                    .tr1 {
                        color: #D38B00;
                    }
                    .tr2 {
                        padding: 10px 5px;
                        align-items:flex-start;

                    }
                    .padd {
                        display: flex;
                        .degree {
                            height: 20px;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size:100% 100%;
                        }
                    }
                }
            }
        }
        .analy {
            margin-top: 20px;
        }
    }
}
</style>