<template>
    <div>
        <div class="titleImg">
            <img class="img" :src="headImg" alt="">
        </div>
        <div class="user">
            <div class="itemLi bg1">
                <div class="lebal">姓名 |</div>
                <div class="value">{{userData.Ex1}}</div>
            </div>
            <div class="itemLi bg2">
                <div class="lebal">性别 |</div>
                <div class="value">{{userData.Ex3}}</div>
            </div>
            <div class="itemLi bg1">
                <div class="lebal">出生年月 |</div>
                <div class="value">{{userData.Ex4}}</div>
            </div>
            <div class="itemLi bg2">
                <div class="lebal">注册日期 |</div>
                <div class="value">{{userData.CreateTime}}</div>
            </div>
            <div class="itemLi bg1">
                <div class="lebal">登记编号 |</div>
                <div class="value">{{userData.DataValue}}</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {
        
    },
    data() {
        return {
            
        };
    },
    props:['userData', 'headImg'],
    created() {
        
    },
    mounted() {
        
    },
    methods: {
        
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
.titleImg {
    width: 100%;
}
.user {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    .itemLi {
        width: 60%;
        height: 32px;
        display: flex;
        align-items: center;
        margin-top: 5px;
        margin-left: 18%;
        .lebal {
            width: 30%;
            text-align: end;
        }
        .value {
            width: 70%;
            padding-left: 20px;
        }
    }
    .bg1 {
        background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/userBag.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size:100% 100%;
    }
    .bg2 {
        background: #EAF2F4;
    }
}
</style>